<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:value="state.params.area"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:kabupaten="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AMonthPicker
                    v-model:value="state.params.bulan"
                    :allow-clear="false"
                    placeholder="Pilih Bulan"
                    style="width:100%;"
                    format="MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
            </div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR]) || (hasRoles([ROLE_SPC_GROUP, ROLE_SPC]) && state.isUserPIC)"
                        title="Publish">
                        <AButton
                            :loading="state.isActionPublish"
                            @click="btnActionPublish"
                            type="danger">
                            <span class="fa fa-rss mr-2" aria-hidden="true"/> Publish
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK, ROLE_ASM, ROLE_SSM])"
                        title="Approve">
                        <AButton
                            :loading="state.isActionApprove"
                            @click="btnActionApprove"
                            type="primary">
                            <span class="fa fa-check mr-2" aria-hidden="true"/> Approve
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK, ROLE_ASM, ROLE_SSM])"
                        title="Reject">
                        <AButton
                            :loading="state.isActionReject"
                            @click="btnActionReject"
                            type="danger">
                            <span class="fa fa-ban mr-2" aria-hidden="true"/> Reject
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK])"
                        title="User PIC">
                        <AButton
                            :loading="state.isActionApprove"
                            @click="btnUserPic"
                            type="primary">
                            <span class="fa fa-user-md mr-2" aria-hidden="true"/>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_DISTRIBUTOR]) || (hasRoles([ROLE_SPC_GROUP, ROLE_SPC]) && state.isUserPIC)"
                        title="Upload Dokumen">
                        <AButton
                            type="primary"
                            @click="btnUpload">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK])"
                        title="Upload Dokumen">
                        <ADropdown>
                            <template #overlay>
                                <AMenu @click="btnUpload">
                                    <AMenuItem :key="ROLE_DISTRIBUTOR">
                                        Distributor
                                    </AMenuItem>
                                    <AMenuItem :key="ROLE_SPC">
                                        SPC
                                    </AMenuItem>
                                </AMenu>
                            </template>
                            <AButton type="primary">
                                <span class="fa fa-upload mr-2" aria-hidden="true"/>
                                <DownOutlined />
                            </AButton>
                        </ADropdown>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK]) || (hasRoles([ROLE_SPC_GROUP, ROLE_SPC]) && state.isUserPIC)"
                        title="Tambahkan By SPC">
                        <AButton
                            type="primary"
                            @click="btnFormCreate">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="state.params"
                            namefile="Master-Data-Target-Penjualan-Distributor"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                row-key="id"
                :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Detail Target by SPC">
                            <AButton
                                class="button"
                                size="small"
                                @click="() => router.push({
                                    name: 'master.targetspc.detail',
                                    params: {
                                        id: record.id,
                                    },
                                })">
                                <i class="fa fa-list" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Detail Target per Toko by Distributor">
                            <AButton
                                class="button"
                                size="small"
                                @click="() => router.push({
                                    name: 'master.targetdistributor.detail',
                                    params: {
                                        id: record.id,
                                    },
                                })">
                                <i class="fa fa-list-alt" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Detail Status">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnDetailStatus(record)">
                                <i class="fa fa-asterisk" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>

                <template #expandedRowRender="{index}">
                    <MdTable
                        :columns="state.columnChild"
                        :data-source="[state.data[index]]"
                        :pagination="false">
                    </MdTable>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            :roles="[ROLE_SPC]"
            @success="fetchDataList"/>

        <!-- upload -->
        <Upload
            v-if="btnUploadModal"
            v-model:visible="btnUploadModal"
            :params="state.params"
            :roles="state.roles"
            @success="fetchDataList"/>

        <!-- list detail status -->
        <DetailStatusList
            v-if="visibleDetailStatusModal"
            v-model:visible="visibleDetailStatusModal"
            v-model:item="visibleDetailStatusItemModal"
            @success="fetchDataList"/>

        <!-- list user PIC -->
        <UserPicList
            v-if="visibleUserPicModal"
            v-model:visible="visibleUserPicModal"/>

         <AModal
            v-model:visible="state.result.isShow"
            title="Result"
            @cancel="handleModalCancel"
            @ok="handleModalCancel"
            width="50%">
            <template #footer>
                <AButton key="back" type="primary" @click="handleShowCancel">OK</AButton>
            </template>
            <AAlert type="success" :message="`Sukses: ${state.result.valid}`"></AAlert>
            <AAlert type="error">
                <template #message>
                {{ `Gagal: ${state.result.failed}` }}
                    <a-tree :tree-data="state.result.data" default-expand-all/>
                </template>
            </AAlert>
        </AModal>

    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import { DownOutlined } from '@ant-design/icons-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from './Form'
import Upload from './Upload'
import DetailStatusList from './DetailStatusList'
import UserPicList from './UserPicList'
import _ from 'lodash'
import moment from 'moment'
import localStorage from 'store'
import {
    FormatCurrency,
    FormatNumber,
} from '@/helpers/utils'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_DISTRIBUTOR,
    ROLE_ASM,
    ROLE_SSM,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        DownOutlined,
        FilterRegional,
        FilterProvince,
        FilterArea,
        FilterKabupatenKota,
        FilterDistributor,
        Form,
        Upload,
        DetailStatusList,
        DownloadExcel,
        UserPicList,
    },
    setup() {
        const user = computed(() => localStorage.get('profile'))
        const errorMessage = ref()
        const router = useRouter()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Kabupaten/Kota',
                    dataIndex: 'kabupaten',
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                    children: [
                        {
                            title: 'Target SPC (TON)',
                            dataIndex: 'total_spc',
                            customRender: ({ text }) => FormatNumber(text),
                        },
                        {
                            title: 'Target Distributor (TON)',
                            dataIndex: 'total_distributor',
                            customRender: ({ text }) => FormatNumber(text),
                        },
                    ],
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            columnChild: [],
            endpoint: {
                current: '/api/snop/target-penjualan-distributor',
                publish: '/api/snop/target-penjualan-distributor/action-publish',
                approve: '/api/snop/target-penjualan-distributor/action-approve',
                reject: '/api/snop/target-penjualan-distributor/action-reject',
                access_pic: '/api/snop/target-user-pic-akses',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            roles: [],
            isFetching: false,
            isDownloading: false,
            isActionPublish: false,
            isActionApprove: false,
            isActionReject: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                area: [],
                kabupaten: [],
                distributor: [],
                bulan: moment().format('YYYY-MM-DD'),
                page: 1,
                "per-page": 10,
            }),
            selectedRows: [],
            selectedRowKeys: [],
            result: {
                isShow: false,
                data: [],
                valid: 0,
                failed: 0,
            },
            isUserPIC: true,
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        const handleShowCancel = () => {
            state.result.isShow = false
            handleModalCancel()
        }

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.bulan) {
                state.params.bulan = moment(state.params.bulan).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        function generateBrandColumns(data) {
            // Extract unique brands
            const brands = _.uniqBy(_.flatMap(data, item => item.brands), 'brand_id')

            // Generate brand columns
            return brands.map(brand => ({
                title: brand.brand_name,
                dataIndex: `brand_${brand.brand_id}`,
                children: [
                    {
                        title: 'Target SPC (TON)',
                        dataIndex: `brand_${brand.brand_id}_spc`,
                        customRender: ({ text }) => FormatNumber(text),
                    },
                    {
                        title: 'Target Distributor (TON)',
                        dataIndex: `brand_${brand.brand_id}_distributor`,
                        customRender: ({ text }) => FormatNumber(text),
                    },
                    {
                        title: '%',
                        dataIndex: `brand_${brand.brand_id}_persentase`,
                        customRender: ({ text }) => FormatCurrency(text),
                    },
                ],
            }))
        }

        const transformData = (data) => {
            return _.map(data, item => {
                const transformedItem = _.omit(item, 'brands')
                let totalSpc = 0
                let totalDistributor = 0

                _.forEach(item.brands, brand => {
                    transformedItem[`brand_${brand.brand_id}_spc`] = brand.target_spc
                    transformedItem[`brand_${brand.brand_id}_distributor`] = brand.target_distributor
                    transformedItem[`brand_${brand.brand_id}_persentase`] = brand.persentase

                    totalSpc += _.toInteger(brand.target_spc)
                    totalDistributor += _.toInteger(brand.target_distributor)
                });

                transformedItem['total_spc'] = totalSpc
                transformedItem['total_distributor'] = totalDistributor

                return transformedItem
            })
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.columnChild = generateBrandColumns(items)

                    state.data = transformData(items)
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // fetch user access PIC
        const fetchUserAccessPic = () => {
            apiClient
                .get(state.endpoint.access_pic)
                .then(({ data }) => {
                    state.isUserPIC = data.status
                })
        }

        const sendAction = (action = 'publish') => {
            // reset result
            state.result.data = []
            state.result.valid = 0
            state.result.failed = 0

            if (action == 'publish') {
                state.isActionPublish = true
            } else if (action == 'approve') {
                state.isActionApprove = true
            } else if (action == 'reject') {
                state.isActionReject = true
            }

            let data = {
                allData: false,
                filters: {
                    ...state.params,
                },
                data: state.selectedRows,
            }

            apiClient.post(state.endpoint[action], data)
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length === 0) {
                        message.warning('Tidak ada data!, pastikan sudah memilih datanya')
                        return
                    }

                    state.selectedRowKeys = []
                    fetchDataList()
                    
                    state.result.isShow = true

                    data.forEach((item, idx) => {
                        if (item.success) {
                            state.result.valid++
                        } else {
                            state.result.failed++
                            const branch = {}
                            state.result.data.push(branch)
                            branch.title = 'Baris ' + (idx + 1)
                            branch.key = idx
                            branch.children = []
                            if (item.errors) {
                                item.errors.forEach((v, i) => {
                                    branch.children.push({
                                        title: v,
                                        key: `${idx}-${i}`,
                                    })
                                })
                            }
                        }
                    })
                })
                .catch(e => message.error('Gagal mengirim status!'))
                .finally(() => {
                    state.isActionPublish = false
                    state.isActionApprove = false
                    state.isActionReject = false
                })
        }

        const btnActionPublish = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Publish',
                content: 'Apakah Anda ingin publish?',
                onOk() {
                    sendAction('publish')
                },
                onCancel() { },
            })
        }

        const btnActionApprove = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Approve',
                content: 'Apakah Anda ingin approve?',
                onOk() {
                    sendAction('approve')
                },
                onCancel() { },
            })
        }

        const btnActionReject = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Reject',
                content: 'Apakah Anda ingin reject?',
                onOk() {
                    sendAction('reject')
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false)
        const btnFormCreate = (field = null) => {
            visibleFormModal.value = true
        }

        // handle upload
        const btnUploadModal = ref(false)
        const btnUpload = ({ key }) => {
            btnUploadModal.value = true
            state.roles = key ? [key] : user.value.roles.map(item => item.role) // else default by auth user
        }

        // handle detail status by id
        const visibleDetailStatusModal = ref(false);
        const visibleDetailStatusItemModal = ref(null);

        const btnDetailStatus = (item = null) => {
            visibleDetailStatusModal.value = true
            visibleDetailStatusItemModal.value = item
        }

        // handle detail status by id
        const visibleUserPicModal = ref(false);
        const btnUserPic = () => {
            visibleUserPicModal.value = true
        }

        // handle vue
        onMounted(() => {
            fetchUserAccessPic()
            fetchDataList()
        })

        return {
            router,
            state,
            fetchDataList,
            onSelectChange,
            handleTableChange,
            errorMessage,
            btnUpload,
            btnUploadModal,
            btnDetailStatus,
            visibleDetailStatusModal,
            visibleDetailStatusItemModal,
            visibleFormModal,
            btnFormCreate,
            btnActionPublish,
            btnActionApprove,
            btnActionReject,
            handleModalCancel,
            handleShowCancel,
            visibleUserPicModal,
            btnUserPic,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_DISTRIBUTOR,
            ROLE_ASM,
            ROLE_SSM,
        }
    },
})
</script>
